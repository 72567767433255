import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import FormInput from "../core/Form";
import Between from "../core/Between";
import Range from "../core/Range";
import useWindowDimensions from "../../utils/CustomHooks/useWindowDimension";
import {
  getTopTenCities,
  getTopLocalities,
} from "../../redux/search/search.selector";
import { getAllInstitution } from "../../redux/institution/institution.selector.js";
import { selectCategoryList } from "../../redux/category/category.selector.js";
import { TopTenCitiesApiCall } from "../../apiCalls/Search/TopTenCitiesApiCall";
import { TopLocalitiesApiCall } from "../../apiCalls/Search/TopLocalitiesApiCall";
import { GetAllInstitutionApiCall } from "../../apiCalls/Institution/GetAllInstitutionApiCall.js";
import { AllPropertyTypesApiCall } from "../../apiCalls/Property/AllPropertyTypes.js";
import { CategoryById } from "../../apiCalls/categoryById/CategoryByTypeId.js";
import { PropertySearchListApiCall } from "../../apiCalls/Search/PropertySearchListApiCall";
import {
  getAllPropertyTypes,
  propertyCategoryDetails,
} from "../../redux/property/property.selector";
import {
  RESIDENTIAL_CATEGORIES,
  INDUSTRIAL_CATEGORIES,
  COMMERCIAL_CATEGORIES,
  AGRICULTURAL_CATEGORIES,
  ALL_CATEGORIES,
} from "../../utils/JsonData/Categories";
import { SearchContext } from "../../App.js";

import bankList from "./banklist.json";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Modal from "../core/Model";
import useModalStore from "../Store/model";

const propertyType = [
  "All Types",
  "Residential",
  "Commercial",
  "Industrial",
  "Agricultural",
];

const categoryList = [
  "House",
  "Villa / Bungalow",
  "Flat",
  "Farm House",
  "Residential Plot",
  "others",
];

const bank = ["AXIS BANK", "BANK OF AMERICA", "BANK OF INDIA", "YES BANK"];

const cityList = ["Banglore", "Mumbai", "Pune", "New Delhi"];

const PrettoSlider = styled(Slider)({
  color: "#000",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    top: "8px",
    fontSize: 11,
    background: "unset",
    padding: 0,
    width: 40,
    height: 40,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#ccc",
    color: "#000",
    fontWeight: "bold",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function priceLabelFormat(value) {
  return `${
    value > 100 ? (value === 1500 ? "15Cr+" : value / 100 + "Cr") : value + "L"
  } `;
}

function areaLabelFormat(value) {
  return `${
    // value > 100 ? (value === 110 ? "1C+" : value / 100 + "C") : value + "L"
    value > 10000 ? value / 1000 + "+" : value
  } `;
}

export default function SearchData() {
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const insututeList = useSelector(getAllInstitution);
  const propertyTypesList = useSelector(getAllPropertyTypes);
  let categoryList = useSelector(selectCategoryList);
  const { open, handleOpen, handleClose } = useModalStore((state) => ({
    open: state.open,
    handleOpen: state.handleOpen,
    handleClose: state.handleClose,
  }));

  const topCities = useSelector(getTopTenCities);
  const topLocalities = useSelector(getTopLocalities);
  const categoryDetails = useSelector(propertyCategoryDetails);
  const searchData = useContext(SearchContext).searchData;
  const setSearchData = useContext(SearchContext).setSearchData;
  const setPagenumber = useContext(SearchContext).setSearchPageNumber;
  //advanced
  const [checked, setChecked] = useState(false);
  const mounted = useRef();

  const [price, setPrice] = useState([100, 200]);
  const [area, setArea] = useState([1000, 10000]);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  // type
  const [state, setState] = useState({
    type: "",
    subType: "",
    city: "",
    locality: "",
    bedsSelected: "",
    priceSelected: "",
    propertyId: "",
    minAreaSelected: "",
    maxAreaSelected: "",
    cityData: {},
    localityData: {},
    price: [15, 1500],
    area: [300, 10000],
    bankInsitute: {},
    propertyType: {},
    category: {},
  });

  const resetFilter = () => {
    handleClose();
    setState({
      type: "",
      subType: "",
      city: "",
      locality: "",
      bedsSelected: "",
      priceSelected: "",
      propertyId: "",
      minAreaSelected: "",
      maxAreaSelected: "",
      cityData: "",
      localityData: "",
      price: [15, 1500],
      area: [300, 10000],
      bankInsitute: "",
      propertyType: "",
      category: "",
    });
    setSearchData({
      type: "",
      subType: "",
      city: "",
      locality: "",
      bedsSelected: "",
      priceSelected: "",
      propertyId: "",
      minAreaSelected: "",
      maxAreaSelected: "",
      cityData: "",
      localityData: "",
      pId: "",
      price: [15, 200],
      area: [2000, 6000],
      bankInsitute: null,
      propertyType: null,
      category: null,
    });
  };

  useEffect(() => {
    setState({ ...searchData, price: [15, 1500], area: [300, 10000] });
  }, [searchData]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      dispatch(TopTenCitiesApiCall(state.city));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.city]);

  useEffect(() => {
    if (state.cityData?.city_id?.trim("")?.length > 0) {
      dispatch(TopLocalitiesApiCall(state.locality, state.cityData?.city_id));
    } else {
      dispatch(TopLocalitiesApiCall(state.locality));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.locality]);

  useEffect(() => {
    if (state.propertyType?.type_id) {
      setState({ ...state, category: null });
      dispatch(CategoryById(state.propertyType.type_id));
    }
  }, [state.propertyType, dispatch]);

  useEffect(() => {
    dispatch(GetAllInstitutionApiCall());
    dispatch(AllPropertyTypesApiCall());
  }, [dispatch]);

  const onFilterChange = (event) => {
    const name = event.target.name;
    if (name === "type") {
      setState({
        ...state,
        [name]: event.target.value,
        subType: "",
      });
    } else {
      setState({
        ...state,
        [name]: event.target.value,
      });
    }
  };

  const priceHandler = (event, newValue) => {
    console.log("Line No 259", newValue);
    setPrice(newValue);
    setState({
      ...state,
      price: [newValue[0], newValue[1]],
    });
  };

  const areaHandler = (event, newValue) => {
    setArea(newValue);
    setState({
      ...state,
      area: newValue,
    });
  };

  //residential
  const [residentialList, setResidentialList] = useState([]);
  const [industrialList, setIndustrialList] = useState([]);
  const [commercialList, setCommercialList] = useState([]);
  const [agriculturalList, setAgriculturalList] = useState([]);

  const searchProperty = () => {
    let propertyTypeId = "";
    if (
      categoryDetails.data?.types?.length > 0 &&
      state?.propertyType?.type_name !== "All Types"
    ) {
      let info = categoryDetails.data?.types;
      for (let i = 0; i < info?.length; i++) {
        if (info[i]?.type_name === state.propertyType?.type_name) {
          propertyTypeId = info[i]?.type_id;
        }
      }
    }
    setSearchData({
      ...state,
      pId: propertyTypeId,
    });
    setPagenumber(1);
    if (
      state.category?.category_id?.trim("")?.length > 0 ||
      state.propertyId?.trim("")?.length > 0 ||
      state.bedsSelected?.trim("")?.length > 0 ||
      state.priceSelected?.trim("")?.length > 0 ||
      state.cityData?.city_name?.trim("")?.length > 0 ||
      state.localityData?.locality_name?.trim("")?.length > 0 ||
      state.propertyType?.type_name?.trim("")?.length > 0 ||
      state.bankInsitute?.institution_id
    ) {
      dispatch(
        PropertySearchListApiCall(
          1,
          propertyTypeId ?? "",
          state.category?.category_id ?? "",
          state.propertyId ?? "",
          state.city?.trim("")?.length > 0
            ? state?.cityData?.city_id || ""
            : "",
          state.locality?.trim("")?.length > 0
            ? state.localityData?.locality_id || ""
            : "",
          state.price[0] * 100000,
          state.price[1] * 100000,
          state.area[0],
          state.area[1],
          state.bankInsitute?.institution_id ?? ""
        )
      );
    }
  };

  const [city, setCity] = useState([]);
  const dimensions = useWindowDimensions();

  const cityHandler = (event) => {
    const {
      target: { value },
    } = event;
    setCity(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div>

      {dimensions.width > 900 ? (
        <>
          <Between gap={"10px"}>
            <FormControl sx={{ width: "100%", mb: 2, borderRadius: "8px" }}>
              <Autocomplete
                // freeSolo
                options={insututeList ?? []}
                loading={insututeList?.length ? false : true}
                loadingText="Loading..."
                // disableClearable
                noOptionsText="No Institution"
                getOptionLabel={(option) =>
                  option ? option.institution : "Testo"
                }
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    // Free solo entry, user typed their own string and didn't select from list
                    setState({
                      ...state,
                      bankInsitute: { institution: newValue },
                    });
                  } else if (newValue && newValue.institution) {
                    // User selected an option from the list
                    setState({
                      ...state,
                      bankInsitute: newValue,
                    });
                  } else {
                    // Clear the selection
                    setState({
                      ...state,
                      bankInsitute: null,
                    });
                  }
                }}
                sx={{
                  "&.MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#000 !important",
                    fill: "#000",
                  },
                }}
                value={state.bankInsitute}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select an Institution"
                    variant="outlined"
                    // onChange={(e) =>
                    //   setState({
                    //     ...state,
                    //     city: e.target.value,
                    //   })
                    // }
                    sx={{
                      "&.MuiInputBase-root": {
                        borderRadius: "8px",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#000 !important",
                        fill: "#000",
                      },
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl sx={{ width: "100%", mb: 2, borderRadius: "8px" }}>
              <TextField
                id="outlined-basic"
                label="Property ID"
                variant="outlined"
                sx={{ "& .MuiInputBase-root": { borderRadius: "8px" } }}
                value={state.propertyId}
                onChange={(e) =>
                  setState({
                    ...state,
                    propertyId: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl sx={{ width: "100%", mb: 2, borderRadius: "8px" }}>
              <Autocomplete
                options={propertyTypesList ?? []}
                loading={propertyTypesList?.length ? false : true}
                loadingText="Loading..."
                noOptionsText="No Property"
                getOptionLabel={(option) =>
                  option ? option.type_name : "Testo"
                }
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setState({
                      ...state,
                      propertyType: { type_name: newValue },
                      category: null, 
                    });
                  } else if (newValue && newValue.type_name) {
                    setState({
                      ...state,
                      propertyType: newValue,
                      category: null,
                    });
                    dispatch(CategoryById(newValue.type_id)); 
                  } else {
                    setState({
                      ...state,
                      propertyType: null,
                      category: null, 
                    });
                  }
                }}
                value={state.propertyType}
                sx={{
                  "&.MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#000 !important",
                    fill: "#000",
                  },
                }}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type of Property"
                    variant="outlined"
                    sx={{
                      "&.MuiInputBase-root": {
                        borderRadius: "8px",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#000 !important",
                        fill: "#000",
                      },
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl sx={{ width: "100%", mb: 2, borderRadius: "8px" }}>
              <Autocomplete
                options={categoryList ?? []} 
                loading={categoryList?.length ? false : true}
                loadingText="Loading..."
                noOptionsText="No Category"
                getOptionLabel={(option) =>
                  option ? option.category_new_name : ""
                }
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setState({
                      ...state,
                      category: { category_new_name: newValue }, 
                    });
                  } else if (newValue && newValue.category_new_name) {
                    setState({
                      ...state,
                      category: newValue, 
                    });
                  } else {
                    setState({
                      ...state,
                      category: null, 
                    });
                  }
                }}
                value={state.category}
                disabled={!state.propertyType || categoryList.length === 0} 
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    variant="outlined"
                    sx={{
                      "&.MuiInputBase-root": {
                        borderRadius: "8px",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#000 !important",
                        fill: "#000",
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Between>
          <Between gap={"10px"}>
            <FormControl sx={{ width: "100%", mb: 2, borderRadius: "8px" }}>
              <Autocomplete
                // freeSolo
                // disablePortal
                id="city-dropdown"
                options={topCities.data ?? []}
                disableClearable
                noOptionsText="No cities"
                getOptionLabel={(option) => (option ? option.city_name : "")}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    // Free solo entry, user typed their own string and didn't select from list
                    setState({
                      ...state,
                      cityData: { city_name: newValue },
                    });
                  } else if (newValue && newValue.city_name) {
                    // User selected an option from the list
                    setState({
                      ...state,
                      cityData: newValue,
                    });
                  } else {
                    // Clear the selection
                    setState({
                      ...state,
                      cityData: null,
                    });
                  }
                }}
                sx={{
                  "&.MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#000 !important",
                    fill: "#000",
                  },
                }}
                value={state.cityData}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="City"
                    variant="outlined"
                    onChange={(e) =>
                      setState({
                        ...state,
                        city: e.target.value,
                      })
                    }
                    sx={{
                      "&.MuiInputBase-root": {
                        borderRadius: "8px",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#000 !important",
                        fill: "#000",
                      },
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl sx={{ width: "100%", mb: 2, borderRadius: "8px" }}>
              <Autocomplete
                // freeSolo
                id="locality-dropdown"
                options={topLocalities.data ?? []}
                disableClearable
                noOptionsText="No locations"
                getOptionLabel={(option) => option.locality_name}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    // Free solo entry, user typed their own string and didn't select from list
                    setState({
                      ...state,
                      localityData: { locality_name: newValue },
                    });
                  } else if (newValue && newValue.locality_name) {
                    // User selected an option from the list
                    setState({
                      ...state,
                      localityData: newValue,
                    });
                  } else {
                    // Clear the selection
                    setState({
                      ...state,
                      localityData: null,
                    });
                  }
                }}
                sx={{
                  "&.MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#000 !important",
                    fill: "#000",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location"
                    className="city-label"
                    variant="outlined"
                    onChange={(e) =>
                      setState({
                        ...state,
                        locality: e.target.value,
                      })
                    }
                  />
                )}
              />
            </FormControl>
          </Between>

          {dimensions.width > 900 ? (
            <Box sx={{ width: "100%" }}>
              <Box sx={{ m: 5 }} />
              <Between gap={"35px"}>
                <Box width={"100%"}>
                  <Between py={1}>
                    <Typography gutterBottom>Price</Typography>
                    <Typography gutterBottom>Upto 15+ Cr</Typography>
                  </Between>
                  <PrettoSlider
                    valueLabelDisplay="on"
                    aria-label="pretto slider"
                    // defaultValue={25}
                    step={10}
                    min={15}
                    max={1500}
                    value={state.price}
                    onChange={priceHandler}
                    getAriaValueText={priceLabelFormat}
                    valueLabelFormat={priceLabelFormat}
                  />
                </Box>

                <Box width={"100%"}>
                  <Between py={1}>
                    <Typography gutterBottom>Min Area (sq.ft)</Typography>
                    <Typography gutterBottom>10,000+ sq.ft</Typography>
                  </Between>
                  <PrettoSlider
                    valueLabelDisplay="on"
                    aria-label="pretto slider"
                    defaultValue={6000}
                    step={100}
                    min={100}
                    max={10000}
                    value={state.area}
                    onChange={areaHandler}
                    getAriaLabel={areaLabelFormat}
                    valueLabelFormat={areaLabelFormat}
                  />
                </Box>
              </Between>
              <Box sx={{ m: 2 }} />
            </Box>
          ) : (
            <>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ m: 5 }} />
                <Between gap={"35px"}>
                  <Box width={"100%"}>
                    <Between py={1}>
                      <Typography gutterBottom>Price</Typography>
                      <Typography gutterBottom>Upto 15+ Cr</Typography>
                    </Between>
                    <PrettoSlider
                      valueLabelDisplay="on"
                      aria-label="pretto slider"
                      // defaultValue={25}
                      step={10}
                      min={15}
                      max={1500}
                      value={state.price}
                      onChange={priceHandler}
                      getAriaValueText={priceLabelFormat}
                      valueLabelFormat={priceLabelFormat}
                    />
                  </Box>

                  <Box width={"100%"}>
                    <Between py={1}>
                      <Typography gutterBottom>Min Area (sq.ft)</Typography>
                      <Typography gutterBottom>10,000+ sq.ft</Typography>
                    </Between>
                    <PrettoSlider
                      valueLabelDisplay="on"
                      aria-label="pretto slider"
                      defaultValue={6000}
                      step={100}
                      min={100}
                      max={10000}
                      value={state.area}
                      onChange={areaHandler}
                      getAriaLabel={areaLabelFormat}
                      valueLabelFormat={areaLabelFormat}
                    />
                  </Box>
                </Between>
                <Box sx={{ m: 2 }} />
              </Box>

              <Box width={"100%"}>
                <Between py={1}>
                  <Typography gutterBottom>Min Area (sq.ft)</Typography>
                  <Typography gutterBottom>10,000+ sq.ft</Typography>
                </Between>
                <PrettoSlider
                  valueLabelDisplay="on"
                  aria-label="pretto slider"
                  defaultValue={6000}
                  step={100}
                  min={100}
                  max={10000}
                  value={state.area}
                  onChange={areaHandler}
                  getAriaLabel={areaLabelFormat}
                  valueLabelFormat={areaLabelFormat}
                />
              </Box>
            </>
          )}

          <Box>
            <Between gap={"10px"}>
              <Button
                variant="container"
                fullWidth
                sx={{
                  background: "#D71313",
                  color: "#fff",
                  height: "50px",
                  borderRadius: "8px",
                  fontWeight: "bolder",
                  "&:hover": {
                    backgroundColor: "#D71313",
                  },
                }}
                size="large"
                onClick={resetFilter}
              >
                Reset
              </Button>

              <Link
                to={{
                  pathname: "search-results",
                }}
                style={{ width: "100%" }}
              >
                <Button
                  variant="container"
                  fullWidth
                  sx={{
                    background: "rgb(31,69,144)",
                    color: "#fff",
                    height: "50px",
                    borderRadius: "8px",
                    fontWeight: "bolder",
                    "&:hover": {
                      backgroundColor: "rgb(31,69,144,0.3)",
                    },
                  }}
                  size="large"
                  onClick={searchProperty}
                >
                  Search
                </Button>
              </Link>
            </Between>
          </Box>
        </>
      ) : (
        <>
          <FormControl sx={{ width: "100%", mb: 2, borderRadius: "8px" }}>
            <Autocomplete
              // freeSolo
              options={insututeList ?? []}
              loading={insututeList?.length ? false : true}
              loadingText="Loading..."
              // disableClearable
              noOptionsText="No Institution"
              getOptionLabel={(option) =>
                option ? option.institution : "Testo"
              }
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  // Free solo entry, user typed their own string and didn't select from list
                  setState({
                    ...state,
                    bankInsitute: { institution: newValue },
                  });
                } else if (newValue && newValue.institution) {
                  // User selected an option from the list
                  setState({
                    ...state,
                    bankInsitute: newValue,
                  });
                } else {
                  // Clear the selection
                  setState({
                    ...state,
                    bankInsitute: null,
                  });
                }
              }}
              sx={{
                "&.MuiInputBase-root": {
                  borderRadius: "8px",
                },
                "& .MuiSvgIcon-root": {
                  color: "#000 !important",
                  fill: "#000",
                },
              }}
              value={state.bankInsitute}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select an Institution"
                  variant="outlined"
                  // onChange={(e) =>
                  //   setState({
                  //     ...state,
                  //     city: e.target.value,
                  //   })
                  // }
                  sx={{
                    "&.MuiInputBase-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#000 !important",
                      fill: "#000",
                    },
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ width: "100%", mb: 2, borderRadius: "8px" }}>
            <TextField
              id="outlined-basic"
              label="Property ID"
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { borderRadius: "8px" } }}
              value={state.propertyId}
              onChange={(e) =>
                setState({
                  ...state,
                  propertyId: e.target.value,
                })
              }
            />
          </FormControl>

          <FormControl sx={{ width: "100%", mb: 2, borderRadius: "8px" }}>
            <Autocomplete
              options={propertyTypesList ?? []}
              loading={propertyTypesList?.length ? false : true}
              loadingText="Loading..."
              noOptionsText="No Property"
              getOptionLabel={(option) => (option ? option.type_name : "Testo")}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setState({
                    ...state,
                    propertyType: { type_name: newValue },
                    category: null, 
                  });
                } else if (newValue && newValue.type_name) {
                  setState({
                    ...state,
                    propertyType: newValue,
                    category: null,
                  });
                  dispatch(CategoryById(newValue.type_id)); 
                } else {
                  setState({
                    ...state,
                    propertyType: null,
                    category: null, 
                  });
                }
              }}
              value={state.propertyType}
              sx={{
                "&.MuiInputBase-root": {
                  borderRadius: "8px",
                },
                "& .MuiSvgIcon-root": {
                  color: "#000 !important",
                  fill: "#000",
                },
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type of Property"
                  variant="outlined"
                  sx={{
                    "&.MuiInputBase-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#000 !important",
                      fill: "#000",
                    },
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ width: "100%", mb: 2, borderRadius: "8px" }}>
            <Autocomplete
              options={categoryList ?? []} 
              loading={categoryList?.length ? false : true}
              loadingText="Loading..."
              noOptionsText="No Category"
              getOptionLabel={(option) =>
                option ? option.category_new_name : ""
              }
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setState({
                    ...state,
                    category: { category_new_name: newValue }, 
                  });
                } else if (newValue && newValue.category_new_name) {
                  setState({
                    ...state,
                    category: newValue, 
                  });
                } else {
                  setState({
                    ...state,
                    category: null, 
                  });
                }
              }}
              value={state.category}
              disabled={!state.propertyType || categoryList.length === 0} 
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  variant="outlined"
                  sx={{
                    "&.MuiInputBase-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#000 !important",
                      fill: "#000",
                    },
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ width: "100%", mb: 2, borderRadius: "8px" }}>
            <Autocomplete
              // freeSolo
              // disablePortal
              id="city-dropdown"
              options={topCities.data ?? []}
              disableClearable
              noOptionsText="No cities"
              getOptionLabel={(option) => (option ? option.city_name : "")}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  // Free solo entry, user typed their own string and didn't select from list
                  setState({
                    ...state,
                    cityData: { city_name: newValue },
                  });
                } else if (newValue && newValue.city_name) {
                  // User selected an option from the list
                  setState({
                    ...state,
                    cityData: newValue,
                  });
                } else {
                  // Clear the selection
                  setState({
                    ...state,
                    cityData: null,
                  });
                }
              }}
              sx={{
                "&.MuiInputBase-root": {
                  borderRadius: "8px",
                },
                "& .MuiSvgIcon-root": {
                  color: "#000 !important",
                  fill: "#000",
                },
              }}
              value={state.cityData}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  variant="outlined"
                  onChange={(e) =>
                    setState({
                      ...state,
                      city: e.target.value,
                    })
                  }
                  sx={{
                    "&.MuiInputBase-root": {
                      borderRadius: "8px",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#000 !important",
                      fill: "#000",
                    },
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ width: "100%", mb: 2, borderRadius: "8px" }}>
            <Autocomplete
              // freeSolo
              id="locality-dropdown"
              options={topLocalities.data ?? []}
              disableClearable
              noOptionsText="No locations"
              getOptionLabel={(option) => option.locality_name}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  // Free solo entry, user typed their own string and didn't select from list
                  setState({
                    ...state,
                    localityData: { locality_name: newValue },
                  });
                } else if (newValue && newValue.locality_name) {
                  // User selected an option from the list
                  setState({
                    ...state,
                    localityData: newValue,
                  });
                } else {
                  // Clear the selection
                  setState({
                    ...state,
                    localityData: null,
                  });
                }
              }}
              sx={{
                "&.MuiInputBase-root": {
                  borderRadius: "8px",
                },
                "& .MuiSvgIcon-root": {
                  color: "#000 !important",
                  fill: "#000",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  className="city-label"
                  variant="outlined"
                  onChange={(e) =>
                    setState({
                      ...state,
                      locality: e.target.value,
                    })
                  }
                />
              )}
            />
          </FormControl>

          <Box width={"100%"}>
            <Between py={1}>
              <Typography gutterBottom>Price</Typography>
              <Typography gutterBottom>Upto 15+ Cr</Typography>
            </Between>
            <PrettoSlider
              valueLabelDisplay="on"
              aria-label="pretto slider"
              // defaultValue={25}
              step={10}
              min={15}
              max={1500}
              value={state.price}
              onChange={priceHandler}
              getAriaValueText={priceLabelFormat}
              valueLabelFormat={priceLabelFormat}
            />
          </Box>

          <Box width={"100%"}>
            <Between py={1}>
              <Typography gutterBottom>Min Area (sq.ft)</Typography>
              <Typography gutterBottom>10,000+ sq.ft</Typography>
            </Between>
            <PrettoSlider
              valueLabelDisplay="on"
              aria-label="pretto slider"
              defaultValue={6000}
              step={100}
              min={100}
              max={10000}
              value={state.area}
              onChange={areaHandler}
              getAriaLabel={areaLabelFormat}
              valueLabelFormat={areaLabelFormat}
            />
          </Box>

          <Box>
            <Between gap={"10px"}>
              <Button
                variant="container"
                fullWidth
                sx={{
                  background: "#D71313",
                  color: "#fff",
                  height: "50px",
                  borderRadius: "8px",
                  fontWeight: "bolder",
                }}
                size="large"
                onClick={resetFilter}
              >
                Reset
              </Button>
              <Link
                to={{
                  pathname: "search-results",
                }}
                style={{ width: "100%" }}
              >
                <Button
                  variant="container"
                  fullWidth
                  sx={{
                    background: "rgb(31,69,144)",
                    color: "#fff",
                    height: "50px",
                    borderRadius: "8px",
                    fontWeight: "bolder",
                  }}
                  size="large"
                  onClick={searchProperty}
                >
                  Search
                </Button>
              </Link>
            </Between>
          </Box>
        </>
      )}
    </div>
  );
}
